import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../context/apiContext';
import {formatDate, formatTime, formatCamelCase} from '../../utils/functions';

import ReactModal from 'react-modal'

import PageTemplate from '../../components/pageTemplate';
import {
    Text,
    IndexTable,
    useIndexResourceState,
    Page,
} from '@shopify/polaris';

export default function OrdersDetailed() {
    const { orderId } = useParams()

    const { ordersChildren, ordersFetchChildren, kebabize } = useContext(ApiContext)
    useEffect(() => {
        ordersFetchChildren(orderId)
        window.scrollTo(0,0)
    }, [])

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(ordersChildren);

    const removedKeys = ['parentOrder','createdAt','updatedAt','shopifySite','objectId']
    let orderKeys = Object.keys(ordersChildren[0]).filter(currStr => !removedKeys.includes(currStr))

    let orderTitles = orderKeys.map(currentItem => {
        return {
            id: currentItem,
            title: (
                <Text as='span'>
                    {currentItem}
                </Text>
            )
        }
    })

    const orderRows = ordersChildren.map((currentObj, index) => {

        const orderRowItems = orderKeys.map((currItm, index) => {
            return (
            <IndexTable.Cell key={index}>
                <Text as="span">
                    {currentObj[currItm]}
                </Text>
            </IndexTable.Cell>)
        })
        return <IndexTable.Row
            id= {currentObj.objectId}
            key= {currentObj.objectId}
            position={index}
        >
            {orderRowItems}
        </IndexTable.Row>
    })

    return(
        <>
            <PageTemplate title={`Order Rows - ${orderId}`} backButton={true}>
                <IndexTable
                    itemCount={ordersChildren.length}
                    selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={orderTitles}
                        selectable={false}
                    >
                        {orderRows}
                </IndexTable>
            </PageTemplate>
        </>
    )
}
