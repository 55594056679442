import React, { Component } from "react";
import './styles/app.scss';

import Settings from "./pages/settings/";
import Inventory from "./pages/inventory";
import InventoryDetailed from "./pages/inventoryDetailed";
import Orders from "./pages/orders";
import AmazonOrders from "./pages/orders/amazon";
import OrdersDetailed from "./pages/ordersDetailed";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

class App extends Component {
  constructor(props) {
      super(props);

  }
  componentDidMount = () => {
  }

  render() {
    return (
      <div>
        <Router>
          <div>
            <Routes>
              <Route exact path="/inventory" element={<Inventory />}/>
              <Route path='/inventory/:inventoryId' element={<InventoryDetailed />}/>

              <Route exact path="/orders" element={<Orders />}/>
              <Route exact path="/amazonOrders" element={<AmazonOrders />}/>
              <Route path='/orders/:orderId' element={<OrdersDetailed />}/>

              <Route path="/settings" element={<Settings />}/>
              <Route exact path="/" element={<Settings />}/>
            </Routes>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
