import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../context/apiContext';
import {formatCamelCase} from '../../utils/functions';
import ReactJson from 'react-json-view'
import ReactModal from 'react-modal';

import PageTemplate from '../../components/pageTemplate';

import {
    Tag,
    Text,
    IndexTable,
    TextField,
    Icon,
    useIndexResourceState,
} from '@shopify/polaris';

import {
    SearchMajor
} from '@shopify/polaris-icons';

export default function InventoryDetailed() {
    const { inventoryId } = useParams()

    const { inventoryChildren, inventoryFetchChildren, kebabize } = useContext( ApiContext )

    useEffect(() =>{
        inventoryFetchChildren(inventoryId);
        window.scrollTo(0,0)
    }, [])

    // console.log(inventoryChildren)

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(inventoryChildren);

    const removedKeys = ['shopifyResponse','d365Response', 'parentJob','createdAt','updatedAt','sku','objectId']
    let inventoryKeys = Object.keys(inventoryChildren[0]).filter(currStr => !removedKeys.includes(currStr))

    let inventoryTitles = inventoryKeys.map(currentItem => {
        const nonCamelCase = formatCamelCase(currentItem)
        return {
            id: nonCamelCase,
            title: (
                <Text as='span'>
                    {nonCamelCase}
                </Text>
            )
        }
    })

    inventoryTitles.push( { id: 'shopify-response', title: ( <Text as='span'> D365 Response </Text> ) } )
    inventoryTitles.push( { id: 'd365-response', title: ( <Text as='span'> Shopify Response </Text> ) } )
    inventoryTitles.unshift( { id: 'sku', title: ( <Text as='span'> Sku </Text> ) } )

    /* Search Bar */
    const [searchInput, setSearchInput] = useState('')

    let searchedArray = inventoryChildren
    if(searchInput){
      searchedArray = inventoryChildren.filter(function (currentObj) {
          let currentSku = '' + currentObj.sku
          return currentSku.includes(searchInput)
      })
    }

    console.log(searchedArray)

    const inventoryRows = searchedArray.map((currentObj, index) => {
        const inventoryRowItems = inventoryKeys.map((currItm, index) => {
            return(
                <IndexTable.Cell key={index}>
                    <Text as="span">
                        {currentObj[currItm]}
                    </Text>
                </IndexTable.Cell>
            )
        })

        return <IndexTable.Row
            id= {currentObj.objectId}
            key= {currentObj.objectId}
            position={index}
        >
            <IndexTable.Cell>
                    <Text as='span' fontWeight='bold'>
                        {currentObj.sku}
                    </Text>
            </IndexTable.Cell>
            {inventoryRowItems}
            <IndexTable.Cell>
                <Text>
                    <span className='hover' onClick={() => { handleModalOpen(currentObj.d365Response) }}>
                      <Tag>View</Tag>
                    </span>
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text>
                    <span className='hover' onClick={() => { handleModalOpen(currentObj.shopifyResponse) }}>
                      <Tag>View</Tag>
                    </span>
                </Text>
            </IndexTable.Cell>
        </IndexTable.Row>
    })

    /* MODAL */
    const [ openModal, setOpenModal ] = useState({
        open: false,
        content: null
    })

    function handleModalClose() {
        setOpenModal(prevOpenModal => {
            return {
                ...prevOpenModal,
                open: false,
                content: null
            }
        })
    }

    function handleModalOpen(text) {
        setOpenModal(prevOpenModal => {
            return {
                ...prevOpenModal,
                open: true,
                content: text
            }
        })
    }

    const handleSearchChange = (value) => { setSearchInput(value) }
    const clearSearchBar = () => { setSearchInput('') }

    return(
        <>
        <PageTemplate
          title={`Inventory Log - ${inventoryId}`}
          backButton={true}
          customPrimaryAction={
              <TextField
                value={searchInput}
                onChange={(value) => {handleSearchChange(value)}}
                autoComplete='off'
                placeholder='Search Sku'
                prefix={<Icon source={SearchMajor} tone="base" />}
                clearButton
                onClearButtonClick={clearSearchBar}
              />
            }>

            {openModal.open ?
                <ReactModal
                  isOpen={openModal.open}
                  onRequestClose={handleModalClose}
                  ariaHideApp={false}
                  >
                    <ReactJson displayDataTypes={false} src={JSON.parse(openModal.content)} />
                </ReactModal>
            :
            <>

                <IndexTable
                itemCount={inventoryChildren.length}
                selectedItemsCount={
                allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={inventoryTitles}
                selectable={false}
                >
                    {inventoryRows}
                </IndexTable>
              </>
            }
        </PageTemplate>
        </>
    )
}
