import React from 'react';
import { useNavigate } from 'react-router-dom'
import {
    Page,
    Text,
    Divider,
    LegacyCard,
    Button
} from '@shopify/polaris';

export default function PageTemplate(props) {
  const navigate = useNavigate()
  let primaryAction = props.button && <Button variant="primary" onClick={()=>props.button.onClick()}>{props.button.text}</Button>;
  if(props.customPrimaryAction){
    primaryAction = props.customPrimaryAction
  }
  return(
        <Page
          backAction = {props.backButton && {onAction: () => { navigate(-1) }}}
          fullWidth = {true}
          title = {props.title}
          primaryAction={primaryAction}
          >
            <Divider />
            &nbsp;
            <LegacyCard>
                {props.children}
            </LegacyCard>
        </Page>
    )
}
