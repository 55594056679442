import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../../context/apiContext';
import { Link } from 'react-router-dom';
import {formatDate, formatTime, formatCamelCase} from '../../utils/functions';
import {colors} from '../../utils/constants';
import PageTemplate from '../../components/pageTemplate';

import {
    Icon,
    Text,
    IndexTable,
    useIndexResourceState,
    Button,
} from '@shopify/polaris';
import {
    CircleTickMinor,
    CircleDisabledMajor,
    SearchIcon,
    XIcon,
} from '@shopify/polaris-icons';

export default function Inventory() {
    const {fetchShopifySite, shopifySite, inventoryParents, inventoryFetchParents, kebabize, runInventorySync } = useContext(ApiContext)

    fetchShopifySite()
    useEffect(() => {
       console.log(location)
        inventoryFetchParents()
        window.scrollTo(0,0)
    }, [])

    const removedKeys = ['objectId', 'startTime', 'endTime', 'createdAt','updatedAt','shopifySite','source','completed']
    let inventoryKeys = Object.keys(inventoryParents[0]).filter(currStr => !removedKeys.includes(currStr))
    // console.log(inventoryKeys)
    let inventoryTitles = inventoryKeys.map(currentItem => {
        const nonCamelCase = formatCamelCase(currentItem)
        return {
            id: nonCamelCase,
            title: <Text as='span'> {nonCamelCase} </Text>
        }
    })
    inventoryTitles.push( { id: 'completed', title: ( <Text as='span'> Completed</Text> ) } )
    inventoryTitles.unshift( { id: 'end-time', title: ( <Text as='span'> End Time</Text> ) } )
    inventoryTitles.unshift( { id: 'start-time', title: ( <Text as='span'> Start Time</Text> ) } )
    inventoryTitles.unshift( { id: 'date', title: ( <Text as='span'> Date </Text> ) } )
    inventoryTitles.unshift( { id: 'object-id', title: ( <Text as='span'> Job </Text> ) } )

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(inventoryParents);


    const inventoryRows = inventoryParents.map((currentObj, index) => {
        const inventoryRowItems = inventoryKeys.map((currItm, index) => {
            return (
                <IndexTable.Cell key={index}>
                    <Text as="span" fontWeight='medium'>
                        {currentObj[currItm]}
                    </Text>
                </IndexTable.Cell>
            )
        })
        return <IndexTable.Row>
            <IndexTable.Cell>
                <Link to={`/inventory/${currentObj.objectId}`} dataPrimaryLink >
                    <Text fontWeight='bold' as='span'>
                        {currentObj.objectId}
                    </Text>
                </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.startTime != "undefined" ? formatDate(currentObj.startTime.iso) : ' '} `}
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.startTime != "undefined" ? formatTime(currentObj.startTime.iso) : ' '} `}
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.endTime != "undefined" ? formatTime(currentObj.endTime.iso) : ' '} `}
                </Text>
            </IndexTable.Cell>
            {inventoryRowItems}
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                  {currentObj.completed ? <Icon source={CircleTickMinor} tone="success" /> : <Icon source={CircleDisabledMajor} tone="critical" />}
                </Text>
            </IndexTable.Cell>
        </IndexTable.Row>
    })

    return(
    <>
    <PageTemplate title='Inventory Jobs' button={{text:'Run Inventory Sync',onClick:()=>runInventorySync()}}>
        <IndexTable
            itemCount={inventoryParents.length}
            selectedItemsCount={
            allResourcesSelected ? 'All' : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={inventoryTitles}
            selectable={false}
        >
            {inventoryRows}
        </IndexTable>
    </PageTemplate>
    </>
    )
}
