import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app';
import { AppProvider } from '@shopify/polaris';
import { ApiContextProvider } from './context/apiContext';
import '@shopify/polaris/build/esm/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApiContextProvider>
      <AppProvider>
        <App />
      </AppProvider>
    </ApiContextProvider>
  </React.StrictMode>
);
