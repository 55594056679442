export const formatDate = (date) =>{
  let d = new Date(date);
  return d.toLocaleDateString()
}

export const formatTime = (date) =>{
  let d = new Date(date);
  return d.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  })
}

export const formatCamelCase = (string) =>{
  var rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
  string = string.charAt(0).toUpperCase() + string.slice(1);
  return string.replace( rex, '$1$4 $2$3$5' );
}
