import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../../context/apiContext';
import { Link } from 'react-router-dom';
import './orders.scss'
import {formatDate, formatTime, formatCamelCase} from '../../utils/functions';
import ReactJson from 'react-json-view'

import ReactModal from 'react-modal'

import PageTemplate from '../../components/pageTemplate';
import {
    Tag,
    Text,
    IndexTable,
    useIndexResourceState,
} from '@shopify/polaris';

export default function Orders(){
    const {fetchShopifySite, shopifySite, ordersParents, ordersFetchParents, kebabize, reImportAmazonOrder } = useContext(ApiContext)

    fetchShopifySite()
    useEffect(() => {
        console.log(location)
        ordersFetchParents(true)
        window.scrollTo(0,0)
    }, [])

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(ordersParents);
    console.log(ordersParents[ordersParents.length-1])
    const removedKeys = ['failedImportEmailSent','objectId', 'shopifyOrder','createdAt', 'updatedAt','shopifySite','source','shopifyOrderTags']
    let orderKeys = Object.keys(ordersParents[ordersParents.length-1]).filter(currStr => !removedKeys.includes(currStr))

    let orderTitles = orderKeys.map(currentItem => {
        const nonCamelCase = formatCamelCase(currentItem)
        return {
            id: nonCamelCase,
            title: (
                <Text as='span'>
                    {nonCamelCase}
                </Text>
            )
        }
    })
    orderTitles.push( { id: 'shopify-tags', title: ( <Text as='span'> Shopify Tags </Text> ) } )
    orderTitles.push( { id: 'shopify-order', title: ( <Text as='span'> Shopify Order </Text> ) } )
    orderTitles.push( { id: 'shopify-action', title: ( <Text as='span'> Action </Text> ) } )
    orderTitles.unshift( { id: 'time', title: ( <Text as='span'> Time</Text> ) } )
    orderTitles.unshift( { id: 'date', title: ( <Text as='span'> Date </Text> ) } )
    orderTitles.unshift( { id: 'object-id', title: ( <Text as='span'> Order Id </Text> ) } )

    const orderRows = ordersParents.map((currentObj, index) => {

        const orderRowItems = orderKeys.map((currItm, index) => {
            return (
            <IndexTable.Cell key={index}>
                <Text as="span">
                    {currentObj[currItm]}
                </Text>
            </IndexTable.Cell>)
        })
        return <IndexTable.Row
            id= {currentObj.shopifyOrderId}
            key= {currentObj.shopifyOrderId}
            position= {index}
        >
            <IndexTable.Cell>
                <Link to={`/orders/${currentObj.objectId}`} >
                    <Text fontWeight='bold' as='span'>
                        {currentObj.shopifyOrderNumber}
                    </Text>
                </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.createdAt != "undefined" ? formatDate(currentObj.createdAt) : ' '} `}
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.createdAt != "undefined" ? formatTime(currentObj.createdAt) : ' '} `}
                </Text>
            </IndexTable.Cell>
            {orderRowItems}
            <IndexTable.Cell>
                {currentObj.shopifyOrderTags && currentObj.shopifyOrderTags.split(',').map((tag) => {return(<span style={{marginRight:3}}><Tag>{tag}</Tag></span>)})}
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text>
                    <span className='hover' onClick={() => { handleModalOpen(JSON.stringify(currentObj.shopifyOrder)) }}>
                      <Tag>View</Tag>
                    </span>
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text>
                    {currentObj.failedImportEmailSent && !currentObj.d365OrderId &&
                      <span className='hover' onClick={() => { handleReimportOrder(currentObj.amazonOrderId) }}>
                        <Tag style={{backgroundColor:'red'}}>Re Import Order</Tag>
                      </span>
                    }
                </Text>
            </IndexTable.Cell>
        </IndexTable.Row>
    })

    /* Modal */

    const [ openModal, setOpenModal ] = useState({
        open: false,
        content: null
    })

    function handleModalClose() {
        setOpenModal(prevOpenModal => {
            return {
                ...prevOpenModal,
                open: false,
                content: null
            }
        })
    }

    function handleModalOpen(text) {
        setOpenModal(prevOpenModal => {
            return {
                ...prevOpenModal,
                open: true,
                content: text
            }
        })
    }
    function handleReimportOrder(amazonOrderId) {
      console.log(amazonOrderId)
      if (confirm("This action will re-import the order into dynamics, check to make sure the order is not already there. Are you sure you want to continue?") == true) {
        reImportAmazonOrder(amazonOrderId)
        alert("Order Re Imported. It may take up to 10min to appear in D365")
      }
    }

    return(
        <>
            <PageTemplate title='Amazon Orders' backButton={true}>
                {openModal.open ?
                    <ReactModal isOpen={openModal.open} onRequestClose={handleModalClose} ariaHideApp={false}>
                        <ReactJson displayDataTypes={false} src={JSON.parse(openModal.content)} />
                    </ReactModal>
                :
                    <IndexTable
                    itemCount={ordersParents.length}
                    selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={orderTitles}
                        selectable={false}
                    >
                        {orderRows}
                    </IndexTable>
                }
            </PageTemplate>
        </>
    )
}
